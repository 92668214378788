// External Imports
import { List, Datagrid, ListGuesser } from "react-admin";

// Internal Imports
import { getDynamicField } from "./fields/DynamicField";

const DynamicList = ({ schema }) => {
  if (Array.isArray(schema) && schema.length > 0) {
    return (
      <List>
        <Datagrid rowClick="show">{schema.map((field) => getDynamicField(field))}</Datagrid>
      </List>
    );
  } else {
    return <ListGuesser />;
  }
};

export default DynamicList;
