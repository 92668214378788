// External Import
import Receipt from "@mui/icons-material/Receipt";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// amount: INTEGER
// type: STRING
// description: TEXT
// paymentMethod: STRING
// incurredAt: DATE

// claimId: UUID
// coverageId: UUID
// payeeId: UUID
// expenseId -> files

export const expenses = {
  title: "Expenses",
  icon: Receipt, // Sales
  path: "expenses",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Amount", source: "amount", type: dataTypes.NUMBER },
    { label: "Description", source: "description", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Amount", source: "amount", type: dataTypes.NUMBER },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Payment Method", source: "paymentMethod", type: dataTypes.TEXT },
    { label: "Incurred At", source: "incurredAt", type: dataTypes.DATETIME },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    { label: "Coverage", source: "coverageId", reference: "coverages", type: dataTypes.REFERENCE },
    { label: "Payee", source: "payeeId", reference: "payees", type: dataTypes.REFERENCE },
    { label: "Files", reference: "files", target: "expenseId", type: dataTypes.MANY_REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Amount", source: "amount", type: dataTypes.NUMBER },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Payment Method", source: "paymentMethod", type: dataTypes.TEXT },
    { label: "Incurred At", source: "incurredAt", type: dataTypes.DATETIME },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    { label: "Coverage", source: "coverageId", reference: "coverages", type: dataTypes.REFERENCE },
    { label: "Payee", source: "payeeId", reference: "payees", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Amount", source: "amount", type: dataTypes.NUMBER },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Payment Method", source: "paymentMethod", type: dataTypes.TEXT },
    { label: "Incurred At", source: "incurredAt", type: dataTypes.DATETIME },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    { label: "Coverage", source: "coverageId", reference: "coverages", type: dataTypes.REFERENCE },
    { label: "Payee", source: "payeeId", reference: "payees", type: dataTypes.REFERENCE },
  ],
};
