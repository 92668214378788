// External Imports
import { defaultTheme } from "react-admin";

export const lightTheme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: "#46c695",
    },
    secondary: {
      main: "#2d3c57",
    },
  },
};

export const darkTheme = {
  ...defaultTheme,
  palette: {
    mode: "dark",
    primary: {
      main: "#46c695",
    },
    secondary: {
      main: "#2d3c57",
    },
  },
};
