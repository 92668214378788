// External Import
import ContactPage from "@mui/icons-material/ContactPage";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// name: STRING
// type: STRING
// status: STRING
// description: TEXT
// policyNumber: STRING
// effectiveDate: DATE
// expiryDate: DATE
// renewalDate: DATE
// canceledDate: DATE
// premium: INTEGER
// deductible: INTEGER
// limitedAccess: BOOLEAN
// paidInFull: BOOLEAN
// isMonoline: BOOLEAN
// formOfBusiness: STRING
// insuredFirstName: STRING
// insuredMiddleName: STRING
// insuredLastName: STRING

// insuranceCompanyId: UUID
// assetId: UUID
// agentId: UUID
// policyId -> coverages
// policyId -> claims
// policyId -> documents

export const policies = {
  title: "Policies",
  icon: ContactPage,
  path: "policies",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Status", source: "status", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Policy Number", source: "policyNumber", type: dataTypes.TEXT },
    { label: "Effective Date", source: "effectiveDate", type: dataTypes.DATE },
    { label: "Expiry Date", source: "expiryDate", type: dataTypes.DATE },
    { label: "Renewal Date", source: "renewalDate", type: dataTypes.DATE },
    { label: "Canceled Date", source: "canceledDate", type: dataTypes.DATE },
    { label: "Premium", source: "premium", type: dataTypes.NUMBER },
    { label: "Deductible", source: "deductible", type: dataTypes.NUMBER },
    { label: "Limited Access", source: "limitedAccess", type: dataTypes.BOOLEAN },
    { label: "Paid In Full", source: "paidInFull", type: dataTypes.BOOLEAN },
    { label: "Is Monoline", source: "isMonoline", type: dataTypes.BOOLEAN },
    { label: "Form Of Business", source: "formOfBusiness", type: dataTypes.TEXT },
    { label: "Insured First Name", source: "insuredFirstName", type: dataTypes.TEXT },
    { label: "Insured Middle Name", source: "insuredMiddleName", type: dataTypes.TEXT },
    { label: "Insured Last Name", source: "insuredLastName", type: dataTypes.TEXT },
    {
      label: "Insurance Company",
      source: "insuranceCompanyId",
      reference: "insurance-companies",
      type: dataTypes.REFERENCE,
    },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Agent", source: "agentId", reference: "agents", type: dataTypes.REFERENCE },
    {
      label: "Coverages",
      reference: "coverages",
      target: "policyId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Claims",
      reference: "claims",
      target: "policyId",
      type: dataTypes.MANY_REFERENCE,
    },
    { label: "Documents", reference: "documents", target: "policyId", type: dataTypes.MANY_REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Status", source: "status", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Policy Number", source: "policyNumber", type: dataTypes.TEXT },
    { label: "Effective Date", source: "effectiveDate", type: dataTypes.DATE },
    { label: "Expiry Date", source: "expiryDate", type: dataTypes.DATE },
    { label: "Renewal Date", source: "renewalDate", type: dataTypes.DATE },
    { label: "Canceled Date", source: "canceledDate", type: dataTypes.DATE },
    { label: "Premium", source: "premium", type: dataTypes.NUMBER },
    { label: "Deductible", source: "deductible", type: dataTypes.NUMBER },
    { label: "Limited Access", source: "limitedAccess", type: dataTypes.BOOLEAN },
    { label: "Paid In Full", source: "paidInFull", type: dataTypes.BOOLEAN },
    { label: "Is Monoline", source: "isMonoline", type: dataTypes.BOOLEAN },
    { label: "Form Of Business", source: "formOfBusiness", type: dataTypes.TEXT },
    { label: "Insured First Name", source: "insuredFirstName", type: dataTypes.TEXT },
    { label: "Insured Middle Name", source: "insuredMiddleName", type: dataTypes.TEXT },
    { label: "Insured Last Name", source: "insuredLastName", type: dataTypes.TEXT },
    {
      label: "Insurance Company",
      source: "insuranceCompanyId",
      reference: "insurance-companies",
      type: dataTypes.REFERENCE,
    },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Agent", source: "agentId", reference: "agents", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Status", source: "status", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Policy Number", source: "policyNumber", type: dataTypes.TEXT },
    { label: "Effective Date", source: "effectiveDate", type: dataTypes.DATE },
    { label: "Expiry Date", source: "expiryDate", type: dataTypes.DATE },
    { label: "Renewal Date", source: "renewalDate", type: dataTypes.DATE },
    { label: "Canceled Date", source: "canceledDate", type: dataTypes.DATE },
    { label: "Premium", source: "premium", type: dataTypes.NUMBER },
    { label: "Deductible", source: "deductible", type: dataTypes.NUMBER },
    { label: "Limited Access", source: "limitedAccess", type: dataTypes.BOOLEAN },
    { label: "Paid In Full", source: "paidInFull", type: dataTypes.BOOLEAN },
    { label: "Is Monoline", source: "isMonoline", type: dataTypes.BOOLEAN },
    { label: "Form Of Business", source: "formOfBusiness", type: dataTypes.TEXT },
    { label: "Insured First Name", source: "insuredFirstName", type: dataTypes.TEXT },
    { label: "Insured Middle Name", source: "insuredMiddleName", type: dataTypes.TEXT },
    { label: "Insured Last Name", source: "insuredLastName", type: dataTypes.TEXT },
    {
      label: "Insurance Company",
      source: "insuranceCompanyId",
      reference: "insurance-companies",
      type: dataTypes.REFERENCE,
    },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Agent", source: "agentId", reference: "agents", type: dataTypes.REFERENCE },
  ],
};
