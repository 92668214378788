// External Import
import Chair from "@mui/icons-material/Chair";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// name: STRING
// type: STRING
// category: STRING
// description: TEXT
// purchaseDate: DATE
// purchasePrice: INTEGER
// replacementCost: INTEGER
// comparableLink: TEXT
// condition: STRING
// manufacturer: STRING
// model: STRING
// serialNumber: STRING

// assetId: UUID
// assetAttributeId: UUID
// personalItemId -> claimItems
// personalItemId -> files

export const personalItems = {
  title: "Personal Items",
  icon: Chair,
  path: "personal-items",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Category", source: "category", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Purchase Date", source: "purchaseDate", type: dataTypes.DATE },
    { label: "Purchase Price", source: "purchasePrice", type: dataTypes.NUMBER },
    { label: "Replacement Cost", source: "replacementCost", type: dataTypes.NUMBER },
    { label: "Comparable Link", source: "comparableLink", type: dataTypes.URL },
    { label: "Condition", source: "condition", type: dataTypes.TEXT },
    { label: "Manufacturer", source: "manufacturer", type: dataTypes.TEXT },
    { label: "Model", source: "model", type: dataTypes.TEXT },
    { label: "Serial Number", source: "serialNumber", type: dataTypes.TEXT },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
    {
      label: "Asset Attribute",
      source: "assetAttributeId",
      reference: "asset-attributes",
      type: dataTypes.REFERENCE,
    },
    {
      label: "Claim Items",
      reference: "claim-items",
      target: "personalItemId",
      type: dataTypes.MANY_REFERENCE,
    },
    { label: "Files", reference: "files", target: "personalItemId", type: dataTypes.MANY_REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Category", source: "category", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Purchase Date", source: "purchaseDate", type: dataTypes.DATE },
    { label: "Purchase Price", source: "purchasePrice", type: dataTypes.NUMBER },
    { label: "Replacement Cost", source: "replacementCost", type: dataTypes.NUMBER },
    { label: "Comparable Link", source: "comparableLink", type: dataTypes.URL },
    { label: "Condition", source: "condition", type: dataTypes.TEXT },
    { label: "Manufacturer", source: "manufacturer", type: dataTypes.TEXT },
    { label: "Model", source: "model", type: dataTypes.TEXT },
    { label: "Serial Number", source: "serialNumber", type: dataTypes.TEXT },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
    {
      label: "Asset Attribute",
      source: "assetAttributeId",
      reference: "asset-attributes",
      type: dataTypes.REFERENCE,
    },
  ],
  editSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Category", source: "category", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Purchase Date", source: "purchaseDate", type: dataTypes.DATE },
    { label: "Purchase Price", source: "purchasePrice", type: dataTypes.NUMBER },
    { label: "Replacement Cost", source: "replacementCost", type: dataTypes.NUMBER },
    { label: "Comparable Link", source: "comparableLink", type: dataTypes.URL },
    { label: "Condition", source: "condition", type: dataTypes.TEXT },
    { label: "Manufacturer", source: "manufacturer", type: dataTypes.TEXT },
    { label: "Model", source: "model", type: dataTypes.TEXT },
    { label: "Serial Number", source: "serialNumber", type: dataTypes.TEXT },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
    {
      label: "Asset Attribute",
      source: "assetAttributeId",
      reference: "asset-attributes",
      type: dataTypes.REFERENCE,
    },
  ],
};
