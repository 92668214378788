// External Import
import Home from "@mui/icons-material/Home";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// name: STRING

// locationId: UUID
// assetId -> assets

export const mortgagees = {
  title: "Mortgagees",
  icon: Home,
  path: "mortgagees",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Location", source: "locationId", reference: "locations", type: dataTypes.REFERENCE },
    {
      label: "Assets",
      reference: "assets",
      target: "assetId",
      type: dataTypes.MANY_REFERENCE,
    },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Location", source: "locationId", reference: "locations", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Location", source: "locationId", reference: "locations", type: dataTypes.REFERENCE },
  ],
};
