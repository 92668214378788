// External Import
import Label from "@mui/icons-material/Label";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID

// claimId: UUID
// assetAttributeId: UUID
// personalItemId: UUID
// claimItemId -> files

export const claimItems = {
  title: "Claim Items",
  icon: Label,
  path: "claim-items",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    {
      label: "Asset Attribute",
      source: "assetAttributeId",
      reference: "asset-attributes",
      type: dataTypes.REFERENCE,
    },
    {
      label: "Personal Item",
      source: "personalItemId",
      reference: "personal-items",
      type: dataTypes.REFERENCE,
    },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    {
      label: "Asset Attribute",
      source: "assetAttributeId",
      reference: "asset-attributes",
      type: dataTypes.REFERENCE,
    },
    {
      label: "Personal Item",
      source: "personalItemId",
      reference: "personal-items",
      type: dataTypes.REFERENCE,
    },
    { label: "Files", reference: "files", target: "assetAttributeId", type: dataTypes.MANY_REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    {
      label: "Asset Attribute",
      source: "assetAttributeId",
      reference: "asset-attributes",
      type: dataTypes.REFERENCE,
    },
    {
      label: "Personal Item",
      source: "personalItemId",
      reference: "personal-items",
      type: dataTypes.REFERENCE,
    },
  ],
  editSchema: [
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    {
      label: "Asset Attribute",
      source: "assetAttributeId",
      reference: "asset-attributes",
      type: dataTypes.REFERENCE,
    },
    {
      label: "Personal Item",
      source: "personalItemId",
      reference: "personal-items",
      type: dataTypes.REFERENCE,
    },
  ],
};
