// External Imports
import {
  BooleanField,
  TextField,
  EmailField,
  UrlField,
  RichTextField,
  NumberField,
  ArrayField,
  DateField,
  ImageField,
  FileField,
  SelectField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
} from "react-admin";

// Internal Imports
import JsonField from "./JsonField";

// --- Types ---
// ARRAY
// BOOLEAN
// DATE
// DATETIME
// DOCUMENT
// IMAGE
// NUMBER
// REFERENCE
// MANY_REFERENCE
// RICH_TEXT
// SELECT
// MULTI_SELECT
// TEXT
// TIME
// EMAIL
// URL
// SECRET
// JSON
// PHONE_NUMBER

export const getDynamicField = (field) => {
  if (field.type === undefined) {
    console.error("Field type is undefined");
  }

  switch (field.type) {
    case "BOOLEAN":
      return <BooleanField key={field.label} label={field.label} source={field.source} />;

    case "TEXT":
    case "SECRET":
    case "PHONE_NUMBER":
      return <TextField key={field.label} label={field.label} source={field.source} />;

    case "JSON":
      return <JsonField key={field.label} label={field.label} source={field.source} />;

    case "RICH_TEXT":
      return <RichTextField key={field.label} label={field.label} source={field.source} />;

    case "EMAIL":
      return <EmailField key={field.label} label={field.label} source={field.source} />;

    case "URL":
      return <UrlField key={field.label} label={field.label} source={field.source} />;

    case "NUMBER":
      return <NumberField key={field.label} label={field.label} source={field.source} />;

    case "ARRAY":
      return <ArrayField key={field.label} label={field.label} source={field.source} />;

    case "DATE":
    case "TIME":
    case "DATETIME":
      return <DateField key={field.label} label={field.label} source={field.source} />;

    case "IMAGE":
      return <ImageField key={field.label} label={field.label} source={field.source} />;

    case "DOCUMENT":
      return <FileField key={field.label} label={field.label} source={field.source} />;

    case "SELECT":
      return (
        <SelectField key={field.label} label={field.label} source={field.source} choices={field.options} />
      );

    case "MULTI_SELECT":
      return (
        <SelectField key={field.label} label={field.label} source={field.source} choices={field.options} />
      );

    case "REFERENCE":
      return (
        <ReferenceField
          key={field.label}
          label={field.label}
          source={field.source}
          reference={field.reference}
        />
      );

    case "MANY_REFERENCE":
      return (
        <ReferenceManyField
          key={field.label}
          label={field.label}
          reference={field.reference}
          target={field.target}
        >
          <Datagrid>
            <TextField source="id" />
          </Datagrid>
        </ReferenceManyField>
      );

    default:
      return <TextField key={field.label} label={field.label} source={field.source} />;
  }
};

// export default DynamicField;
