// External Imports
import { Edit, SimpleForm, EditGuesser } from "react-admin";

// Internal Imports
import { getDynamicInput } from "./inputs/DynamicInput";

const DynamicEdit = ({ schema }) => {
  if (Array.isArray(schema) && schema.length > 0) {
    return (
      <Edit>
        <SimpleForm>{schema.map((field) => getDynamicInput(field))}</SimpleForm>
      </Edit>
    );
  } else {
    return <EditGuesser />;
  }
};

export default DynamicEdit;
