// External Import
import FormatListNumbered from "@mui/icons-material/FormatListNumbered";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// completed: BOOLEAN
// completedAt: DATE
// hidden: BOOLEAN

// userId: UUID
// claimId: UUID
// baseTaskId: UUID

export const claimTasks = {
  title: "Claim Tasks",
  icon: FormatListNumbered,
  path: "claim-tasks",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    { label: "Completed", source: "completed", type: dataTypes.BOOLEAN },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Completed", source: "completed", type: dataTypes.BOOLEAN },
    { label: "Completed At", source: "completedAt", type: dataTypes.DATETIME },
    { label: "Hidden", source: "hidden", type: dataTypes.BOOLEAN },
    { label: "User", source: "userId", reference: "users", type: dataTypes.REFERENCE },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    { label: "Base Task", source: "baseTaskId", reference: "base-tasks", type: dataTypes.REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Completed", source: "completed", type: dataTypes.BOOLEAN },
    { label: "Completed At", source: "completedAt", type: dataTypes.DATETIME },
    { label: "Hidden", source: "hidden", type: dataTypes.BOOLEAN },
    { label: "User", source: "userId", reference: "users", type: dataTypes.REFERENCE },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    { label: "Base Task", source: "baseTaskId", reference: "base-tasks", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Completed", source: "completed", type: dataTypes.BOOLEAN },
    { label: "Completed At", source: "completedAt", type: dataTypes.DATETIME },
    { label: "Hidden", source: "hidden", type: dataTypes.BOOLEAN },
    { label: "User", source: "userId", reference: "users", type: dataTypes.REFERENCE },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    { label: "Base Task", source: "baseTaskId", reference: "base-tasks", type: dataTypes.REFERENCE },
  ],
};
