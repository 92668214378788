// External Import
import Flood from "@mui/icons-material/Flood";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID;
// type: STRING;
// description: TEXT;

// damageId: UUID -> file_damages -> files

export const damages = {
  title: "Damages",
  icon: Flood,
  path: "damages",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
  ],
  editSchema: [
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
  ],
};
