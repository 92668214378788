// External Import
import Store from "@mui/icons-material/Store";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// name: STRING
// website: TEXT
// branding: JSONB

// userId: UUID
// payeeId -> expenses

export const payees = {
  title: "Payees",
  icon: Store,
  path: "payees",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Website", source: "website", type: dataTypes.URL },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Website", source: "website", type: dataTypes.URL },
    { label: "Branding", source: "branding", type: dataTypes.JSON },
    { label: "User", source: "userId", reference: "assets", type: dataTypes.REFERENCE },
    {
      label: "Expenses",
      reference: "expenses",
      target: "payeeId",
      type: dataTypes.MANY_REFERENCE,
    },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Website", source: "website", type: dataTypes.URL },
    { label: "Branding", source: "branding", type: dataTypes.JSON },
    { label: "User", source: "userId", reference: "assets", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Website", source: "website", type: dataTypes.URL },
    { label: "Branding", source: "branding", type: dataTypes.JSON },
    { label: "User", source: "userId", reference: "assets", type: dataTypes.REFERENCE },
  ],
};
