// Exteral Imports
import { Auth } from "aws-amplify";
import axios from "axios";

// Internal Imports
import { formatApiUrl } from "./utils";

const client = axios.create({
  withCredentials: true,
  baseURL: formatApiUrl(process.env.REACT_APP_ADMIN_API_URL, process.env.REACT_APP_ADMIN_API_VERSION),
});

client.interceptors.request.use(
  (config) =>
    Auth.currentSession()
      .then((session) => {
        config.headers["Authorization"] = `Bearer ${session.accessToken.jwtToken}`;
        return config;
      })
      .catch((err) => {
        return config;
      }),
  (error) => Promise.reject(error)
);

export default client;
