// External Import
import Paid from "@mui/icons-material/Paid";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// amount: INTEGER
// description: TEXT
// paidAt: DATE

// claimId: UUID
// coverageId: UUID
// claimPaymentId -> files

export const claimPayments = {
  title: "Claim Payments",
  icon: Paid, // Savings
  path: "claim-payments",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Amount", source: "amount", type: dataTypes.NUMBER },
    { label: "Description", source: "description", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Amount", source: "amount", type: dataTypes.NUMBER },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Paid At", source: "paidAt", type: dataTypes.DATETIME },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    { label: "Coverage", source: "coverageId", reference: "coverages", type: dataTypes.REFERENCE },
    { label: "Files", reference: "files", target: "claimPaymentId", type: dataTypes.MANY_REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Amount", source: "amount", type: dataTypes.NUMBER },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Paid At", source: "paidAt", type: dataTypes.DATETIME },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    { label: "Coverage", source: "coverageId", reference: "coverages", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Amount", source: "amount", type: dataTypes.NUMBER },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Paid At", source: "paidAt", type: dataTypes.DATETIME },
    { label: "Claim", source: "claimId", reference: "claims", type: dataTypes.REFERENCE },
    { label: "Coverage", source: "coverageId", reference: "coverages", type: dataTypes.REFERENCE },
  ],
};
