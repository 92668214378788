// External Imports
import { Show, SimpleShowLayout, ShowGuesser } from "react-admin";

// Internal Imports
import { getDynamicField } from "./fields/DynamicField";

const DynamicShow = ({ schema }) => {
  if (Array.isArray(schema) && schema.length > 0) {
    return (
      <Show>
        <SimpleShowLayout>{schema.map((field) => getDynamicField(field))}</SimpleShowLayout>
      </Show>
    );
  } else {
    return <ShowGuesser />;
  }
};

export default DynamicShow;
