// External Imports
import { ToggleThemeButton, AppBar } from "react-admin";
import { Typography } from "@mui/material";

// Internal Imports
import { lightTheme, darkTheme } from "theme";

const Header = (props) => (
  <AppBar {...props}>
    <Typography flex="1" variant="h6" id="react-admin-title"></Typography>
    <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} />
  </AppBar>
);

export default Header;
