// External Imports
import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
import { Auth } from "aws-amplify";

// Internal Imports
import { formatApiUrl } from "./utils";

const client = async (url, options = {}) => {
  await Auth.currentSession()
    .then((session) => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
      }
      options.headers.set("Authorization", `Bearer ${session.accessToken.jwtToken}`);
    })
    .catch((err) => {
      console.error("No current session", err);
    });

  return fetchUtils.fetchJson(url, options);
};

export const AdminDataProvider = ({ apiVersion, apiUrl }) => {
  if (!apiVersion) {
    throw new Error("Missing or invalid api version");
  }

  if (!apiUrl) {
    throw new Error("Missing or invalid api url");
  }

  return simpleRestProvider(formatApiUrl(apiUrl, apiVersion), client);
};
