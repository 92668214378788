// External Imports
import { Create, SimpleForm, EditGuesser } from "react-admin";

// Internal Imports
import { getDynamicInput } from "./inputs/DynamicInput";

const DynamicCreate = ({ schema }) => {
  if (Array.isArray(schema) && schema.length > 0) {
    return (
      <Create>
        <SimpleForm>{schema.map((field) => getDynamicInput(field))}</SimpleForm>
      </Create>
    );
  } else {
    return <EditGuesser />;
  }
};

export default DynamicCreate;
