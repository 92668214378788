// External Import
import Engineering from "@mui/icons-material/Engineering";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// type: STRING
// name: STRING
// email: STRING
// phoneNumber: STRING

// insuranceCompanyId: UUID
// locationId: UUID
// photoId: UUID

export const professionals = {
  title: "Professionals",
  icon: Engineering,
  path: "professionals",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Email", source: "email", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Email", source: "email", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Phone Number", source: "phoneNumber", type: dataTypes.TEXT },
    {
      label: "Insurance Company",
      source: "insuranceCompanyId",
      reference: "insurance-companies",
      type: dataTypes.REFERENCE,
    },
    { label: "Location", source: "locationId", reference: "locations", type: dataTypes.REFERENCE },
    { label: "Photo", source: "photoId", reference: "photos", type: dataTypes.REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Email", source: "email", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Phone Number", source: "phoneNumber", type: dataTypes.TEXT },
    {
      label: "Insurance Company",
      source: "insuranceCompanyId",
      reference: "insurance-companies",
      type: dataTypes.REFERENCE,
    },
    { label: "Location", source: "locationId", reference: "locations", type: dataTypes.REFERENCE },
    { label: "Photo", source: "photoId", reference: "photos", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Email", source: "email", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Phone Number", source: "phoneNumber", type: dataTypes.TEXT },
    {
      label: "Insurance Company",
      source: "insuranceCompanyId",
      reference: "insurance-companies",
      type: dataTypes.REFERENCE,
    },
    { label: "Location", source: "locationId", reference: "locations", type: dataTypes.REFERENCE },
    { label: "Photo", source: "photoId", reference: "photos", type: dataTypes.REFERENCE },
  ],
};
