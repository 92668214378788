// External Import
import Cottage from "@mui/icons-material/Cottage";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// name: STRING
// apn: STRING
// type: STRING
// category: STRING
// yearBuilt: INTEGER
// lastRenovationDate: INTEGER
// purchaseDate: DATE
// purchasePrice: INTEGER
// value: INTEGER
// replacementCost: INTEGER
// assessedImprovementValue: INTEGER
// assessedLandValue: INTEGER
// assessedTotalValue: INTEGER
// marketImprovementValue: INTEGER
// marketLandValue: INTEGER
// marketTotalValue: INTEGER
// bedrooms: INTEGER
// bathrooms: INTEGER
// stories: INTEGER
// units: INTEGER
// parkingSpaces: INTEGER
// sqft: INTEGER
// shape: STRING
// allOwnerNames: ARRAY
// allMortgages: ARRAY
// mortgageLoanNumber: STRING
// canopyUpdatedAt: DATE
// updatedAt: DATETIME
// createdAt: DATETIME
// deletedAt: DATETIME

// ownerId: UUID
// locationId: UUID
// mortgageeId: UUID
// photoId: UUID

// assetId -> files
// assetId -> policies
// assetId -> claims
// assetId -> assetAttributes
// assetId -> personalItems

export const assets = {
  title: "Assets",
  icon: Cottage,
  path: "assets",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "APN", source: "apn", type: dataTypes.TEXT },
    { label: "Category", source: "category", type: dataTypes.TEXT },
    { label: "Year Built", source: "yearBuilt", type: dataTypes.NUMBER },
    { label: "Last Renovation Date", source: "lastRenovationDate", type: dataTypes.NUMBER },
    { label: "Purchase Date", source: "purchaseDate", type: dataTypes.DATE },
    { label: "Purchase Price", source: "purchasePrice", type: dataTypes.NUMBER },
    { label: "Value", source: "value", type: dataTypes.NUMBER },
    { label: "ReplacementCost", source: "replacementCost", type: dataTypes.NUMBER },
    { label: "Assessed Improvement Value", source: "assessedImprovementValue", type: dataTypes.NUMBER },
    { label: "Assessed Land Value", source: "assessedLandValue", type: dataTypes.NUMBER },
    { label: "Assessed Total Value", source: "assessedTotalValue", type: dataTypes.NUMBER },
    { label: "Market Improvement Value", source: "marketImprovementValue", type: dataTypes.NUMBER },
    { label: "Market Land Value", source: "marketLandValue", type: dataTypes.NUMBER },
    { label: "Market Total Value", source: "marketTotalValue", type: dataTypes.NUMBER },
    { label: "Bedrooms", source: "bedrooms", type: dataTypes.NUMBER },
    { label: "Bathrooms", source: "bathrooms", type: dataTypes.NUMBER },
    { label: "Stories", source: "stories", type: dataTypes.NUMBER },
    { label: "Units", source: "units", type: dataTypes.NUMBER },
    { label: "Parking Spaces", source: "parkingSpaces", type: dataTypes.NUMBER },
    { label: "Size (sqft)", source: "sqft", type: dataTypes.NUMBER },
    { label: "Shape", source: "shape", type: dataTypes.TEXT },
    { label: "All Owner Names", source: "allOwnerNames", type: dataTypes.ARRAY },
    { label: "All Mortgages", source: "allMortgages", type: dataTypes.ARRAY },
    { label: "Mortgage Loan Number", source: "mortgageLoanNumber", type: dataTypes.TEXT },
    { label: "Canopy Updated At", source: "canopyUpdatedAt", type: dataTypes.NUMBER },
    { label: "Owner", source: "ownerId", reference: "users", type: dataTypes.REFERENCE },
    { label: "Location", source: "locationId", reference: "locations", type: dataTypes.REFERENCE },
    { label: "Mortgagee", source: "mortgageeId", reference: "mortgagees", type: dataTypes.REFERENCE },
    { label: "Cover Photo", source: "photoId", reference: "photos", type: dataTypes.REFERENCE },
    {
      label: "Policies",
      reference: "policies",
      target: "assetId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Claims",
      reference: "claims",
      target: "assetId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Asset Attributes",
      reference: "asset-attributes",
      target: "assetId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Personal Items",
      reference: "personal-items",
      target: "assetId",
      type: dataTypes.MANY_REFERENCE,
    },
    { label: "Files", reference: "files", target: "assetId", type: dataTypes.MANY_REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "APN", source: "apn", type: dataTypes.TEXT },
    { label: "Category", source: "category", type: dataTypes.TEXT },
    { label: "Year Built", source: "yearBuilt", type: dataTypes.NUMBER },
    { label: "Last Renovation Date", source: "lastRenovationDate", type: dataTypes.NUMBER },
    { label: "Purchase Date", source: "purchaseDate", type: dataTypes.DATE },
    { label: "Purchase Price", source: "purchasePrice", type: dataTypes.NUMBER },
    { label: "Value", source: "value", type: dataTypes.NUMBER },
    { label: "ReplacementCost", source: "replacementCost", type: dataTypes.NUMBER },
    { label: "Assessed Improvement Value", source: "assessedImprovementValue", type: dataTypes.NUMBER },
    { label: "Assessed Land Value", source: "assessedLandValue", type: dataTypes.NUMBER },
    { label: "Assessed Total Value", source: "assessedTotalValue", type: dataTypes.NUMBER },
    { label: "Market Improvement Value", source: "marketImprovementValue", type: dataTypes.NUMBER },
    { label: "Market Land Value", source: "marketLandValue", type: dataTypes.NUMBER },
    { label: "Market Total Value", source: "marketTotalValue", type: dataTypes.NUMBER },
    { label: "Bedrooms", source: "bedrooms", type: dataTypes.NUMBER },
    { label: "Bathrooms", source: "bathrooms", type: dataTypes.NUMBER },
    { label: "Stories", source: "stories", type: dataTypes.NUMBER },
    { label: "Units", source: "units", type: dataTypes.NUMBER },
    { label: "Parking Spaces", source: "parkingSpaces", type: dataTypes.NUMBER },
    { label: "Size (sqft)", source: "sqft", type: dataTypes.NUMBER },
    { label: "Shape", source: "shape", type: dataTypes.TEXT },
    // { label: "All Owner Names", source: "allOwnerNames", type: dataTypes.ARRAY },
    // { label: "All Mortgages", source: "allMortgages", type: dataTypes.ARRAY },
    { label: "Mortgage Loan Number", source: "mortgageLoanNumber", type: dataTypes.TEXT },
    { label: "Canopy Updated At", source: "canopyUpdatedAt", type: dataTypes.NUMBER },
    { label: "Owner", source: "ownerId", reference: "users", type: dataTypes.REFERENCE },
    { label: "Location", source: "locationId", reference: "locations", type: dataTypes.REFERENCE },
    { label: "Mortgagee", source: "mortgageeId", reference: "mortgagees", type: dataTypes.REFERENCE },
    { label: "Cover Photo", source: "photoId", reference: "photos", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "APN", source: "apn", type: dataTypes.TEXT },
    { label: "Category", source: "category", type: dataTypes.TEXT },
    { label: "Year Built", source: "yearBuilt", type: dataTypes.NUMBER },
    { label: "Last Renovation Date", source: "lastRenovationDate", type: dataTypes.NUMBER },
    { label: "Purchase Date", source: "purchaseDate", type: dataTypes.DATE },
    { label: "Purchase Price", source: "purchasePrice", type: dataTypes.NUMBER },
    { label: "Value", source: "value", type: dataTypes.NUMBER },
    { label: "ReplacementCost", source: "replacementCost", type: dataTypes.NUMBER },
    { label: "Assessed Improvement Value", source: "assessedImprovementValue", type: dataTypes.NUMBER },
    { label: "Assessed Land Value", source: "assessedLandValue", type: dataTypes.NUMBER },
    { label: "Assessed Total Value", source: "assessedTotalValue", type: dataTypes.NUMBER },
    { label: "Market Improvement Value", source: "marketImprovementValue", type: dataTypes.NUMBER },
    { label: "Market Land Value", source: "marketLandValue", type: dataTypes.NUMBER },
    { label: "Market Total Value", source: "marketTotalValue", type: dataTypes.NUMBER },
    { label: "Bedrooms", source: "bedrooms", type: dataTypes.NUMBER },
    { label: "Bathrooms", source: "bathrooms", type: dataTypes.NUMBER },
    { label: "Stories", source: "stories", type: dataTypes.NUMBER },
    { label: "Units", source: "units", type: dataTypes.NUMBER },
    { label: "Parking Spaces", source: "parkingSpaces", type: dataTypes.NUMBER },
    { label: "Size (sqft)", source: "sqft", type: dataTypes.NUMBER },
    { label: "Shape", source: "shape", type: dataTypes.TEXT },
    // { label: "All Owner Names", source: "allOwnerNames", type: dataTypes.ARRAY },
    // { label: "All Mortgages", source: "allMortgages", type: dataTypes.ARRAY },
    { label: "Mortgage Loan Number", source: "mortgageLoanNumber", type: dataTypes.TEXT },
    { label: "Canopy Updated At", source: "canopyUpdatedAt", type: dataTypes.NUMBER },
    { label: "Owner", source: "ownerId", reference: "users", type: dataTypes.REFERENCE },
    { label: "Location", source: "locationId", reference: "locations", type: dataTypes.REFERENCE },
    { label: "Mortgagee", source: "mortgageeId", reference: "mortgagees", type: dataTypes.REFERENCE },
    { label: "Cover Photo", source: "photoId", reference: "photos", type: dataTypes.REFERENCE },
  ],
};
