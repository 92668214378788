// External Imports
import { useRecordContext } from "react-admin";

const JsonField = (props) => {
  const record = useRecordContext(props);

  if (record) {
    return (
      <p style={{ whiteSpace: "pre" }}>
        {record[props.source] ? JSON.stringify(record[props.source], null, "\t") : ""}
      </p>
    );
  } else {
    return null;
  }
};

JsonField.defaultProps = { label: "Name" };

export default JsonField;
