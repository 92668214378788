// Internal Imports
// import { canopyConnectPulls } from "./canopyConnectPulls";
import { insuranceCompanies } from "./insuranceCompanies";
import { assetAttributes } from "./assetAttributes";
import { personalItems } from "./personalItems";
import { claimPayments } from "./claimPayments";
import { professionals } from "./professionals";
import { proRequests } from "./proRequests";
import { claimItems } from "./claimItems";
import { mortgagees } from "./mortgagees";
import { claimTasks } from "./claimTasks";
import { baseTasks } from "./baseTasks";
// import { locations } from "./locations";
// import { coverages } from "./coverages";
import { expenses } from "./expenses";
import { policies } from "./policies";
import { damages } from "./damages";
import { assets } from "./assets";
import { payees } from "./payees";
import { claims } from "./claims";
import { users } from "./users";
// import { documents } from "./documents";
// import { reports } from "./reports";
// import { files } from "./files";
// import { photos } from "./photos";
// import { tags } from "./tags";
// import { adminUsers } from "./adminUsers";

export const schema = [
  // canopyConnectPulls,
  insuranceCompanies,
  assetAttributes,
  personalItems,
  claimPayments,
  professionals,
  proRequests,
  claimItems,
  mortgagees,
  claimTasks,
  baseTasks,
  // locations,
  // coverages,
  expenses,
  policies,
  damages,
  assets,
  payees,
  claims,
  users,
  // documents,
  // reports,
  // files,
  // photos,
  // tags,
  // adminUsers,
];
