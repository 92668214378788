// External Import
import AssignmentInd from "@mui/icons-material/AssignmentInd";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// type: STRING
// body: TEXT

// userId: UUID

export const proRequests = {
  title: "Pro Requests",
  icon: AssignmentInd,
  path: "pro-requests",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Body", source: "body", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Body", source: "body", type: dataTypes.TEXT },
    { label: "User", source: "userId", reference: "users", type: dataTypes.REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Body", source: "body", type: dataTypes.TEXT },
    { label: "User", source: "userId", reference: "users", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Body", source: "body", type: dataTypes.TEXT },
    { label: "User", source: "userId", reference: "users", type: dataTypes.REFERENCE },
  ],
};
