// External Import
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// title: STRING
// description: TEXT
// infoLink: TEXT
// order: INTEGER
// inAppFunction: STRING
// optional: BOOLEAN
// updatedAt: DATETIME
// createdAt: DATETIME
// deletedAt: DATETIME

// baseTaskId -> claimTasks

export const baseTasks = {
  title: "Base Tasks",
  icon: FormatListBulleted,
  path: "base-tasks",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Title", source: "title", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Title", source: "title", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Info Link", source: "infoLink", type: dataTypes.URL },
    { label: "Order", source: "order", type: dataTypes.NUMBER },
    { label: "In App Function", source: "inAppFunction", type: dataTypes.TEXT },
    { label: "Optional", source: "optional", type: dataTypes.BOOLEAN },
    {
      label: "Claim Tasks",
      reference: "claim-tasks",
      target: "baseTaskId",
      type: dataTypes.MANY_REFERENCE,
    },

    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Title", source: "title", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Info Link", source: "infoLink", type: dataTypes.URL },
    { label: "Order", source: "order", type: dataTypes.NUMBER },
    { label: "In App Function", source: "inAppFunction", type: dataTypes.TEXT },
    { label: "Optional", source: "optional", type: dataTypes.BOOLEAN },
  ],
  editSchema: [
    { label: "Title", source: "title", type: dataTypes.TEXT },
    { label: "Description", source: "description", type: dataTypes.TEXT },
    { label: "Info Link", source: "infoLink", type: dataTypes.URL },
    { label: "Order", source: "order", type: dataTypes.NUMBER },
    { label: "In App Function", source: "inAppFunction", type: dataTypes.TEXT },
    { label: "Optional", source: "optional", type: dataTypes.BOOLEAN },
  ],
};
