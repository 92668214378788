export const dataTypes = {
  ARRAY: "ARRAY",
  BOOLEAN: "BOOLEAN",
  DATE: "DATE",
  TIME: "TIME",
  DATETIME: "DATETIME",
  DOCUMENT: "DOCUMENT",
  IMAGE: "IMAGE",
  NUMBER: "NUMBER",
  REFERENCE: "REFERENCE",
  MANY_REFERENCE: "MANY_REFERENCE",
  RICH_TEXT: "RICH_TEXT",
  SELECT: "SELECT",
  MULTI_SELECT: "MULTI_SELECT",
  TEXT: "TEXT",
  URL: "URL",
  JSON: "JSON",
};
