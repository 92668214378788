// External Import
import Construction from "@mui/icons-material/Construction";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// perilType: STRING
// claimNumber: STRING
// name: STRING
// lossDescription: TEXT
// dateOfLoss: DATE
// dateClosed: DATE

// ownerId: UUID
// policyId: UUID
// assetId: UUID
// locationId: UUID
// photoId: UUID

// claimId -> claimItems
// claimId -> files
// claimId -> documents
// claimId -> reports
// claimId -> expenses
// claimId -> claimPayments
// claimId -> tasks

export const claims = {
  title: "Claims",
  icon: Construction,
  path: "claims",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Claim Number", source: "claimNumber", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Claim Number", source: "claimNumber", type: dataTypes.TEXT },
    { label: "Peril Type", source: "perilType", type: dataTypes.TEXT },
    { label: "Loss Description", source: "lossDescription", type: dataTypes.TEXT },
    { label: "Date Of Loss", source: "dateOfLoss", type: dataTypes.DATETIME },
    { label: "Date Closed", source: "dateClosed", type: dataTypes.DATETIME },
    { label: "Owner", source: "ownerId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Policy", source: "policyId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Location", source: "locationId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Cover Photo", source: "photoId", reference: "assets", type: dataTypes.REFERENCE },
    {
      label: "Claim Items",
      reference: "claim-items",
      target: "claimId",
      type: dataTypes.MANY_REFERENCE,
    },
    { label: "Files", reference: "files", target: "claimId", type: dataTypes.MANY_REFERENCE },
    { label: "Documents", reference: "documents", target: "claimId", type: dataTypes.MANY_REFERENCE },
    { label: "Reports", reference: "reports", target: "claimId", type: dataTypes.MANY_REFERENCE },
    { label: "Expenses", reference: "expenses", target: "claimId", type: dataTypes.MANY_REFERENCE },
    {
      label: "Claim Payments",
      reference: "claim-payments",
      target: "claimId",
      type: dataTypes.MANY_REFERENCE,
    },
    { label: "Tasks", reference: "claim-tasks", target: "claimId", type: dataTypes.MANY_REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Claim Number", source: "claimNumber", type: dataTypes.TEXT },
    { label: "Peril Type", source: "perilType", type: dataTypes.TEXT },
    { label: "Loss Description", source: "lossDescription", type: dataTypes.TEXT },
    { label: "Date Of Loss", source: "dateOfLoss", type: dataTypes.DATETIME },
    { label: "Date Closed", source: "dateClosed", type: dataTypes.DATETIME },
    { label: "Owner", source: "ownerId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Policy", source: "policyId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Location", source: "locationId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Cover Photo", source: "photoId", reference: "assets", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Claim Number", source: "claimNumber", type: dataTypes.TEXT },
    { label: "Peril Type", source: "perilType", type: dataTypes.TEXT },
    { label: "Loss Description", source: "lossDescription", type: dataTypes.TEXT },
    { label: "Date Of Loss", source: "dateOfLoss", type: dataTypes.DATETIME },
    { label: "Date Closed", source: "dateClosed", type: dataTypes.DATETIME },
    { label: "Owner", source: "ownerId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Policy", source: "policyId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Location", source: "locationId", reference: "assets", type: dataTypes.REFERENCE },
    { label: "Cover Photo", source: "photoId", reference: "assets", type: dataTypes.REFERENCE },
  ],
};
