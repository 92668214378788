// External Import
import Roofing from "@mui/icons-material/Roofing";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// name: STRING
// type: STRING
// data: JSONB
// updatedAt: DATETIME
// createdAt: DATETIME
// deletedAt: DATETIME

// assetId: UUID
// assetAttributeId -> claimItems
// assetAttributeId -> personalItems
// assetAttributeId -> files

export const assetAttributes = {
  title: "Asset Attributes",
  icon: Roofing,
  path: "asset-attributes",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
    {
      label: "Claim Items",
      reference: "claim-items",
      target: "assetAttributeId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Personal Items",
      reference: "personal-items",
      target: "assetAttributeId",
      type: dataTypes.MANY_REFERENCE,
    },
    { label: "Files", reference: "files", target: "assetAttributeId", type: dataTypes.MANY_REFERENCE },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Type", source: "type", type: dataTypes.TEXT },
    { label: "Asset", source: "assetId", reference: "assets", type: dataTypes.REFERENCE },
  ],
};
