const Loading = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        height: "100vh",
        width: "100%",
        color: "rgb(70, 198, 149)",
        fontWeight: 600,
        fontSize: 30,
        paddingBottom: 200,
      }}
    >
      <img
        alt="brelly-login"
        src="/assets/imgs/brellyLogo.png"
        style={{ margin: 20, width: 200, objectFit: "contain" }}
      />
      Loading Brelly Admin...
    </div>
  );
};

export default Loading;
